import { html as beautifyHtml } from 'js-beautify';
import truncate from 'lodash/truncate';
import pluralize from 'pluralize';

function arrayToCSV(data) {
  if (data === null || data.length === 0) {
    return null;
  }

  let csv = 'data:text/csv;charset=utf-8,';

  data.forEach((row) => {
    csv += row.join(',');
    csv += '\n';
  });

  return encodeURI(csv);
}

export function downloadArrayDataCSV(array, downloadFileName) {
  const csv = arrayToCSV(array);
  const link = document.createElement('a');
  link.setAttribute('href', csv);
  link.setAttribute('download', downloadFileName);
  link.click();
}

export const redirectToLink = (link) => {
  const aTag = document.createElement('a');
  aTag.setAttribute('href', link);
  aTag.setAttribute('target', '_blank');
  aTag.setAttribute('rel', 'noreferrer');
  aTag.click();
};

export function FormatDaysToWeeks(days, divide) {
  let weeks = 0;

  if (divide) {
    weeks = days / 7.0;
  } else {
    weeks = days * 7.0;
  }

  return weeks.toLocaleString('en-US', { maximumFractionDigits: 1 });
}

export function dateWeeksBackFromToday(weeks) {
  const days = weeks * 7 - 1;
  return Date.now() - days * 24 * 60 * 60 * 1000;
}

export function formatDateString(date) {
  let dateObject = new Date(date);

  return dateObject.toLocaleDateString();
}

export function formatDateAsDateAndTime(date) {
  let dateObject = new Date(date);

  return dateObject.toLocaleDateString(undefined, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
}

export function FormatNumberString(value, decimalDigits) {
  if (value === null) {
    return 'N/A';
  }

  return value.toLocaleString('en-US', { maximumFractionDigits: decimalDigits });
}

export function FormatPercentString(value, decimalDigits) {
  if (value === null || value === 'NaN') {
    return 'N/A';
  }

  return `${FormatNumberString(value * 100, decimalDigits)} %`;
}

export function FormatCurrencyString(value) {
  if (value === null) {
    return 'N/A';
  }

  return `$${FormatNumberString(value, 2)}`;
}

export function isFoundInKeywordSearch(item, filterText) {
  if (!filterText) {
    return true;
  }

  return filterText
    .toString()
    .toLowerCase()
    .split(',')
    .some(
      (term) =>
        item.name.toLowerCase().indexOf(term.trim()) !== -1 ||
        item.brandName.toLowerCase().indexOf(term.trim()) !== -1 ||
        item.gtiN14.toString().indexOf(term.trim()) !== -1 ||
        item.id.toString().indexOf(term.trim()) !== -1
    );
}

export function StripHTMLTags(htmlString) {
  const htmlMatchPattern = new RegExp('<.+?>');
  let cleanedText = '';

  if (!htmlString) {
    cleanedText = '';
  } else {
    cleanedText = htmlString.replace(htmlMatchPattern, '');
  }

  return cleanedText;
}

export function capitalizeFirstLetter(phrase) {
  return `${phrase.charAt(0).toUpperCase()}${phrase.slice(1)}`;
}

export function hasMarketingContentStatus(desiredMarketingContentStatus, currentMarketingContentStatus) {
  return currentMarketingContentStatus === null ? false : currentMarketingContentStatus === desiredMarketingContentStatus;
}

export function hasVideoUploadStatus(desiredVideoUploadStatus, currentVideoUploadStatus) {
  return currentVideoUploadStatus === null ? false : currentVideoUploadStatus === desiredVideoUploadStatus;
}

export function hasPdfUploadStatus(desiredPdfUploadStatus, currentPdfUploadStatus) {
  return currentPdfUploadStatus === null ? false : currentPdfUploadStatus === desiredPdfUploadStatus;
}

export function hasProductTourStatus(desiredProductTourStatus, currentProductTourStatus) {
  return currentProductTourStatus === null ? false : currentProductTourStatus === desiredProductTourStatus;
}

export function hasComparisonResultStatus(desiredComparisonResultStatus, currentComparisonResultStatus) {
  return currentComparisonResultStatus === null ? false : currentComparisonResultStatus === desiredComparisonResultStatus;
}

export function getDistinctKeywords(keywords) {
  const distinctKeywords = keywords.reduce((array, keyword) => {
    if (!array.map((element) => element.value).includes(keyword.value)) {
      array.push(keyword);
    }

    return array;
  }, []);

  return distinctKeywords;
}
export const convertKeywordsToObjectArray = (keywordArray) => {
  return Object.entries(keywordArray).map((keyword) => ({ value: keyword[0], searchVolume: keyword[1] }));
};

export function sortKeywordsByVolumeDescending(keywords) {
  const groupedKeywords = keywords.reduce((acc, cur) => {
    const cleanedValue = cur.value.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

    if (acc[cleanedValue]) {
      acc[cleanedValue].push(cur);
    } else {
      acc[cleanedValue] = [cur];
    }
    return acc;
  }, {});

  const sortedKeywords = Object.values(groupedKeywords)
    .map((group) => {
      const sortedGroup = group.sort((a, b) => b.searchVolume - a.searchVolume);
      return sortedGroup;
    })
    .sort((a, b) => {
      const aMaxVolume = Math.max(...a.map((keyword) => keyword.searchVolume));
      const bMaxVolume = Math.max(...b.map((keyword) => keyword.searchVolume));
      return bMaxVolume - aMaxVolume;
    })
    .flat();

  return sortedKeywords;
}

export function getFileExtension(path) {
  if (!path.includes('.')) {
    return null;
  }

  if (path.includes('?')) {
    path = path.split('?')[0];
  }

  return path.split('.').pop();
}

export function stripHtml(input) {
  return minifyHtml(input)
    .replace(/<.*?>/gs, ' ')
    .replace(/&nbsp;/g, ' ')
    .replace(/\s{2,}/g, ' ')
    .trim();
}

function htmlToText(input) {
  if (input) {
    if (input.bullets) {
      return input.bullets.map((bullet) => bullet.value).join('\n');
    }
    return input
      .replace(/\n/g, '')
      .replace(/&nbsp;/g, ' ')
      .replace(/&amp;/g, '')
      .replace(/\s{2,}/g, '')
      .replace(/<\/.*?>+/gs, '\n')
      .replace(/<.*?>/g, '')
      .trim();
  }
  return '';
}

export function isWhiteSpace(ch) {
  if (' \t\n\r\v'.indexOf(ch) > -1) {
    return true;
  }
  return false;
}

export function getContentCount(content) {
  if (!content || typeof content !== 'string') {
    return 0;
  }

  let nonHtmlContent = stripHtml(content);

  let wordCount = 0;
  let index = 0;

  // skip whitespace until first word
  while (index < nonHtmlContent.length && isWhiteSpace(nonHtmlContent[index])) index++;

  while (index < nonHtmlContent.length) {
    // check if current char is part of a word
    while (index < nonHtmlContent.length && !isWhiteSpace(nonHtmlContent[index])) index++;

    wordCount++;

    // skip whitespace until next word
    while (index < nonHtmlContent.length && isWhiteSpace(nonHtmlContent[index])) index++;
  }

  return wordCount;
}

export function filterArray(array, object, key) {
  var index = array.findIndex((o) => o[key] === object[key]);

  if (index === -1) {
    array.push(object);
  } else {
    array.splice(index, 1);
  }

  return array;
}

export const delay = (ms) => {
  return new Promise((success, error) => {
    setTimeout(() => {
      success();
    }, ms);
  });
};

export const createFieldChangeHandler = (stateObject, setStateObject) => {
  return (callback) => {
    return (event) => {
      let newStateObject = { ...stateObject };
      callback(newStateObject)(event.target.value);
      setStateObject(newStateObject);
    };
  };
};

export const cloneObject = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

//https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid/2117523#2117523
export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export function htmlToPlainText(html) {
  return stripHtml(html.replace(/<\/p>/g, '\n').replace(/&nbsp;/g, ' '));
}

export function contentToPlainText(content) {
  if (content && content.bullets) {
    return content.bullets.map((bullet) => bullet.value).join('\n');
  }
  if (typeof content === 'string') {
    return htmlToPlainText(content);
  }
}

export const minifyHtml = (html) => {
  if (!html) return html;
  return html
    .replace(/\n/g, ' ')
    .replace(/[\t ]+\</g, '<')
    .replace(/\>[\t ]+\</g, '><')
    .replace(/\>[\t ]+$/g, '>')
    .replace(/>\s+|\s+</g, (m) => m.trim());
};

export const formatHtml = (html) => {
  if (!html) return html;
  //split tag content onto a new line
  html = html
    .replace(/(<.*?>)(<.*?>)/g, '$1\n$2')
    .replace(/(<.*?>)(\S)/g, '$1\n$2')
    .replace(/(\S)(<.*?>)/g, '$1\n$2');
  //indent
  return beautifyHtml(html);
};

export function isKeywordUsed(contentText, keyword) {
  const escapeRegExp = (str) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const makeSingularPluralPattern = (keyword) => {
    const words = keyword.split(' ').map((word) => word.trim());
    const patternParts = words.map((word) => {
      const singular = pluralize.singular(word);
      const plural = pluralize.plural(word);

      if (singular.toLowerCase() === plural.toLowerCase()) {
        return `\\b${escapeRegExp(singular)}\\b`;
      }

      return `\\b(${escapeRegExp(singular)}|${escapeRegExp(plural)})\\b`;
    });

    return patternParts.join('\\s+');
  };

  const keywordPattern = makeSingularPluralPattern(keyword);
  const keywordRegExp = new RegExp(keywordPattern, 'gi');

  return keywordRegExp.test(contentText.toLowerCase());
}

export const copyUnusedKeywordsToClipboard = (keywords, contentText) => {
  let keywordsToCopy = sortKeywordsByVolumeDescending(keywords)
    .filter((keyword) => !isKeywordUsed(contentText, keyword.value))
    .map((keyword) => keyword.value);

  //length is the max character count for keywords field on retail link
  const unusedKeywordString = truncate(keywordsToCopy.join(', '), { length: 4000, separator: ', ', omission: '' });

  navigator.clipboard.writeText(unusedKeywordString);
};

function createKeywordArray(keywords) {
  return [['Keywords', 'Search Volume', 'Ranking Score', 'Source']].concat(
    sortKeywordsByVolumeDescending(keywords).map((keyword) => [
      keyword.value,
      keyword.searchVolume,
      keyword?.rankingScore,
      keyword.source == 'JungleScout' ? 'Amazon (Jungle Scout)' : keyword.source == 'GoogleAds' ? 'Google Ads' : keyword.source,
    ])
  );
}

export function createUrlFilterFromObject(filters) {
  return Object.entries(filters)
    .map((keyValueArray) => `${keyValueArray[0]}=${keyValueArray[1]}`)
    .join('&');
}

export const downloadKeywordsToCsv = (keywords) => {
  const keywordArray = createKeywordArray(keywords);
  const now = new Date();
  const dateString = now.toISOString().split('T')[0];

  downloadArrayDataCSV(keywordArray, `${dateString}.csv`);
};

export const downloadClientReviewToTxt = (contentDraft, contentSpecification, gtin14, retailerSpecificId, brand) => {
  let titlesByKeys = Object.assign(
    {},
    ...contentSpecification.sections.map((section) => {
      if (section.type === 'ContentGroup') {
        return section.children.reduce((obj, child) => ({ ...obj, [child.key]: child.title }), {});
      } else {
        return { [section.key]: section.title };
      }
    })
  );

  let draftText = `GTIN14: ${gtin14}\nRetailer Specific Id: ${retailerSpecificId}\n\n`.concat(
    contentSpecification.sections
      .map((section) => section.key)
      .map((key) => `${titlesByKeys[key]}:\n\n`.concat(htmlToText(contentDraft[key]).concat('\n\n')))
      .join('')
  );

  draftText = draftText.replace(/(?![^\n]{1,100}$)([^\n]{1,100})\s/g, '$1\n'); //https://stackoverflow.com/questions/14484787/wrap-text-in-javascript

  const newLink = document.createElement('a');
  newLink.download = `${brand}_${gtin14}.txt`;

  const textToBLOB = new Blob([draftText], { type: 'text/plain' });

  if (window.webkitURL != null) {
    newLink.href = window.webkitURL.createObjectURL(textToBLOB);
  } else {
    newLink.href = window.URL.createObjectURL(textToBLOB);
    newLink.style.display = 'none';

    document.body.appendChild(newLink);
  }

  newLink.click();
};

export function getFeedStatusFromXml(xml) {
  const feedStatus = xml.match(/<ns2:ingestionStatus>(.*?)<\/ns2:ingestionStatus>/);

  return feedStatus ? feedStatus[1] : '';
}

export function getFeedErrorFromXml(xml) {
  const feedError = xml.match(/<ns2:description>(.*?)<\/ns2:description>/);

  return feedError ? feedError[1] : '';
}

export function pathJoin(...parts) {
  return parts.join('/').replace(new RegExp('/{1,}', 'g'), '/');
}

export const getQueryParams = () => {
  return new URLSearchParams(window.location.search);
};

export const getMostRecentDraftByType = (drafts, type) => {
  let copywritingDrafts = drafts.filter((contentDraft) => contentDraft.type === type);

  return copywritingDrafts.sort((x, y) => x.created - y.created)[0];
};

export const copyToClipboard = (value, enqueueSnackbar) => {
  navigator.clipboard.writeText(value);
  enqueueSnackbar('Copied', { variant: 'Success', autoHideDuration: 900 });
};

export const RedirectToRetailerProductPage = (channelProduct, text, className) => {
  if (!channelProduct?.retailerSpecificId) {
    return text;
  }

  let url;

  if (channelProduct?.childClient?.retailer?.name == 'Walmart US') {
    url = `https://www.walmart.com/ip/${channelProduct?.retailerSpecificId}`;
  }

  if (channelProduct?.childClient?.retailer?.name == 'Amazon') {
    url = `https://www.amazon.com/dp/${channelProduct?.retailerSpecificId}`;
  }

  if (url) {
    return (
      <a href={url} target="_blank" className={className}>
        {text}
      </a>
    );
  }

  return text;
};

export const CleanClientName = (clientName) => {
  return clientName ? clientName.trim().replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '') : '';
};

// https://stackoverflow.com/a/32108184
export const isEmpty = (obj) => {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
};

export const createSnackbarSuccessMessage = (entityTypeString, actionTypeString, updatedEntityArray) => {
  return `${entityTypeString}${
    updatedEntityArray.length > 1 ? 's' : ''
  } successfully ${actionTypeString}. Please wait a few seconds for the changes to take effect before refreshing.`;
};

export const sortClientsByName = (clients) => {
  return clients.sort((clientA, clientB) => {
    const startsWithAllA = clientA.name.startsWith('All ');
    const startsWithAllB = clientB.name.startsWith('All ');

    if ((startsWithAllA && startsWithAllB) || (!startsWithAllA && !startsWithAllB)) {
      return clientA.name.localeCompare(clientB.name);
    }

    if (startsWithAllA) {
      return -1;
    }

    return 1;
  });
};

// Source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
export const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
};
